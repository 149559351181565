import React, { Component } from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby';
import BlogPost from '../../components/Blog/BlogPost';

class BlogPostTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false
    };
  }

  post = this.props.data.wordpressPost;
  posts = this.props.data.allWordpressPost;
  tags = this.props.data.allWordpressWpTopic;
  allServices = this.props.data.allWordpressWpOurSolutions;
  servicesMap = this.props.data.allWordpressWpOurSolutions.edges.node;

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/posts/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    const previewData = this.state.previewPayload;

    if (this.state.isPreviewMode) {
      this.post.title = previewData.title.rendered;
      this.post.content = previewData.content.rendered;
    }

    return (
      <BlogPost
        post={this.post}
        posts={this.posts}
        tags={this.tags}
        servicesMap={this.servicesMap}
        allServices={this.allServices}
      />
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    wordpressPost(slug: { eq: $slug }) {
      title
      topic
      type
      content
      slug
      date
      our_solutions
      acf {
        seo_title
        seo_canonical
        seo_description
        excerpt
        display_date
        featured_post
        primary_topic
      }
      author {
        name
        slug
        acf {
          author_title
          shortbio
          author_photo {
            source_url
          }
        }
      }
      better_featured_image {
        source_url
        description
        caption
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    allWordpressPost {
      edges {
        node {
          date
          title
          topic
          our_solutions
          type
          slug
          author {
            name
            slug
          }
        }
      }
    }
    allWordpressWpOurSolutions {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
    allWordpressWpTopic {
      edges {
        node {
          id
          name
          slug
          description
          wordpress_id
        }
      }
    }
  }
`;

export default BlogPostTemplate;
